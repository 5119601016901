import { type AxiosResponse } from 'axios';

import {
  type DtoAIModelListResponse,
  type EnumsAIVendor,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class AIGeneralAPI extends BaseAPIService {
  listModels(
    vendor: EnumsAIVendor
  ): Promise<AxiosResponse<DtoAIModelListResponse>> {
    return this.client.get(`/ai/models?vendor=${vendor}`);
  }
}
