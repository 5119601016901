import { type AxiosResponse } from 'axios';

import {
  type DtoEventIntakeForm,
  type DtoEventIntakeFormResponse,
  type DtoEventIntakeSummaryResponse,
  type DtoEventResponse,
  type DtoEventsResponse,
  type DtoGetEventAttendeesResponse,
  type DtoIntakeEventResponse,
  type DtoLiveEventRequestResponse,
  type DtoPublicEventResponse,
  type DtoRecommendEventAttendeesResponse,
  type DtoRequestLiveEventRequest,
  type DtoUpdateEventIntakeFormRequest,
  type EnumsIntakeFormStatus,
} from '@lp-lib/api-service-client/public';

import { type Organizer, type Venue } from '../../types';
import { type GamePack } from '../../types/game';
import { BaseAPIService } from './base.api';
import { type Paginable, Paginator } from './pagination';
import { type VenuesResponse } from './venue.api';

export type EventData = {
  orgId?: string;
  orgName?: string;
  organizerName?: string;
  organizerEmail?: string;
  attendeeEmails?: string;
  prepMin?: number;
  eventTitle?: string;
  eventFirstParagraph?: string;
  hostShoutOut?: string;
};

export type EventType = 'live' | 'ond';

export type CreateEventRequest = {
  organizerUid: string | null;
  gamePackId: string;
  startAt: string;
  endAt: string;
  timezone: string;
  message: string;
  orgId?: string;
  orgName?: string;
  organizerName?: string;
  organizerEmail?: string;
  attendeeEmails?: string;
  prepMin?: number;
  eventTitle?: string;
  eventFirstParagraph?: string;
  hostShoutOut?: string;
  venueId?: string;
  hostUid?: string;
  studioId?: string;
  producerUid?: string;
  type: EventType;
  /**
   * Array of organizer uids.
   */
  attendees: string[];
};

export type UpdateEventRequest = {
  gamePackId?: string;
  startAt?: string;
  endAt?: string | null;
  timezone?: string;
  message?: string;
  attendees?: string[];
  attendeeEmails?: string;
  prepMin?: number;
  venueId?: string;
  hostUid?: string;
  studioId?: string;
  producerUid?: string;
  eventTitle?: string;
  eventFirstParagraph?: string;
  hostShoutOut?: string;
  sendLiveNotify?: boolean;
  organizerName?: string;
};

export interface QueryEventsRequest {
  type: EventType;
  orgId?: string | null;
  orgRef?: string;
}

export type Event = {
  id: string;
  organizer?: Organizer;
  organizerUid?: string | null;
  createdAt: string;
  updatedAt: string;
  sequence: number;
  startAt: string;
  endAt: string;
  timezone: string;
  gamePack: GamePack;
  status: 'active' | 'canceled';
  attendees: Organizer[] | null;
  message: string;
  type: EventType;
  data?: EventData;
  host?: Host;
  producer?: Producer;
  venue?: Venue;
  studio?: Studio;
  hostUid?: string;
  producerUid?: string;
  intakeForm?: DtoEventIntakeForm;
};

export type Host = {
  uid: string;
  name: string;
  email: string;
  active: boolean;
};

export type Producer = {
  uid: string;
  name: string;
  email: string;
  active: boolean;
};

export type Studio = {
  id: string;
  name: string;
  calendarID: string;
};

export type EventResponse = {
  event: Event;
};

export interface EventsResponse extends Paginable {
  events: Event[];
}

export interface HostsResponse {
  hosts: Host[];
}

export interface ProducersResponse {
  producers: Producer[];
}

export interface StudiosResponse {
  studios: Studio[];
}

export class EventAPI extends BaseAPIService {
  requestLiveEvent(req: DtoRequestLiveEventRequest): Promise<AxiosResponse> {
    return this.client.post('/events/request-live', req);
  }

  getLiveEventRequest(
    id: string
  ): Promise<AxiosResponse<DtoLiveEventRequestResponse>> {
    return this.client.get(`/events/live-event-requests/${id}`);
  }

  getRecommendedAttendees(): Promise<
    AxiosResponse<DtoRecommendEventAttendeesResponse>
  > {
    return this.client.get('/events/recommended-attendees');
  }

  create(req: CreateEventRequest): Promise<AxiosResponse<DtoEventResponse>> {
    return this.client.post(`/events`, req);
  }

  get(id: string): Promise<AxiosResponse<DtoEventResponse>> {
    return this.client.get(`/events/${id}`);
  }

  getEvent(id: string): Promise<AxiosResponse<EventResponse>> {
    return this.client.get(`/events/${id}`);
  }

  getPublicEvent(id: string): Promise<AxiosResponse<DtoPublicEventResponse>> {
    return this.client.get(`/events/${id}/public`);
  }

  queryEvents(
    params: QueryEventsRequest,
    pageSize = 30
  ): Paginator<EventsResponse, Event> {
    return new Paginator<EventsResponse, Event>(
      this.client,
      '/events',
      'events',
      {
        size: pageSize,
        config: {
          params: params,
        },
      }
    );
  }

  getMyUpcomingEvents(): Promise<AxiosResponse<DtoEventsResponse>> {
    return this.client.get(`/my/events/upcoming`);
  }

  cancelEvent(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/events/${id}`);
  }

  updateEvent(
    id: string,
    req: UpdateEventRequest
  ): Promise<AxiosResponse<EventResponse>> {
    return this.client.put(`/events/${id}`, req);
  }

  liveHosts(): Promise<AxiosResponse<HostsResponse>> {
    return this.client.get(`/events/live-hosts`);
  }

  liveProducers(): Promise<AxiosResponse<ProducersResponse>> {
    return this.client.get(`/events/live-producers`);
  }

  liveStudio(): Promise<AxiosResponse<StudiosResponse>> {
    return this.client.get(`/events/live-studios`);
  }

  liveVenues(): Promise<AxiosResponse<VenuesResponse>> {
    return this.client.get('/events/live-venues');
  }

  createIntakeForm(
    eventId: string,
    formId?: string
  ): Promise<AxiosResponse<DtoEventIntakeFormResponse>> {
    return this.client.post(`/events/${eventId}/intake`, { formId });
  }

  updateIntakeForm(
    eventId: string,
    formId: string,
    req: DtoUpdateEventIntakeFormRequest
  ): Promise<AxiosResponse<DtoEventIntakeFormResponse>> {
    return this.client.put(`/events/${eventId}/intake/${formId}`, req);
  }

  updateIntakeFormStatus(
    eventId: string,
    formId: string,
    status: EnumsIntakeFormStatus
  ): Promise<AxiosResponse<DtoEventIntakeFormResponse>> {
    return this.client.put(`/events/${eventId}/intake/${formId}/status`, {
      status,
    });
  }

  deleteIntakeForm(eventId: string, formId: string) {
    return this.client.delete(`/events/${eventId}/intake/${formId}`);
  }

  sendIntakeForm(
    eventId: string,
    formId: string
  ): Promise<AxiosResponse<DtoEventIntakeFormResponse>> {
    return this.client.post(`/events/${eventId}/intake/${formId}/send`);
  }

  getIntakeEvent(id: string): Promise<AxiosResponse<DtoIntakeEventResponse>> {
    return this.client.get(`/events/${id}/intake`);
  }

  sendSummary(eventId: string): Promise<AxiosResponse> {
    return this.client.post(`/events/${eventId}/send-summary`);
  }

  sendIntakeSummary(
    eventId: string,
    formId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`/events/${eventId}/intake/${formId}/send-summary`);
  }

  getIntakeSummary(
    eventId: string,
    formId: string
  ): Promise<AxiosResponse<DtoEventIntakeSummaryResponse>> {
    return this.client.get(`/events/${eventId}/intake/${formId}/summary`);
  }

  getPrevEvent(
    eventId: string,
    formId: string
  ): Promise<
    AxiosResponse<{
      event: Event | null;
    }>
  > {
    return this.client.get(`/events/${eventId}/intake/${formId}/prev-event`);
  }

  getEventAttendees(
    eventId: string
  ): Promise<AxiosResponse<DtoGetEventAttendeesResponse>> {
    return this.client.get(`/events/${eventId}/attendees`);
  }
}
